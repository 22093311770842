import * as React from 'react';
import {
    CardContent,
    Grid,
    Link,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";

export const SaveTheDate = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const swipeText = isSmallScreen ? 'Swipe left to learn more' : 'Click the arrows to learn more'
    return (
        <CardContent>
            <Grid
                container
                alignContent={'space-between'}
                sx={{
                    height: '100%',
                }}
            >
                <Grid item xs={12}>
                    <Typography variant="h5" fontSize='24px'>Save the Date</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        sx={{
                            letterSpacing: '18px',
                            fontSize: isSmallScreen ? '48px' : '64px'
                        }}
                    >
                        PALAK
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            letterSpacing: '10px',
                            marginTop: '-20px'
                        }}
                    >
                        AND
                    </Typography>
                    <Typography
                        variant="h1"
                        sx={{
                            letterSpacing: '18px',
                            marginTop: '-20px',
                            fontSize: isSmallScreen ? '48px' : '64px'
                        }}
                    >
                        VIJAY
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">July 12 - 13, 2025</Typography>
                    <Typography variant="h6">Hartford, Connecticut</Typography>
                    <Typography variant="h6">
                        <Link href="https://palak-and-vijay.com/save-the-date">palak-and-vijay.com</Link>
                    </Typography>
                    <Typography variant='h6'>{swipeText}</Typography>
                </Grid>
            </Grid>

        </CardContent>
    );
}
