import * as React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import {SaveTheDateRoute} from "./SaveTheDateRoute";

export const RouteContainer = () => {
    return (
        <Routes>
            <Route path="/*" element={<Navigate to="/save-the-date" replace/>}/>
            <Route path="/save-the-date" element={<SaveTheDateRoute/>}/>
        </Routes>
    );
}
