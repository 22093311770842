export const normalize_paths = (input) => {
    return input.toLowerCase().replace(/\s+/g, '-');
}

export const timeDifference = (targetDate) => {
    const now = new Date();
    const target = new Date(targetDate);

    const diffInSeconds = target <= now ? Math.floor((now - target) / 1000) : Math.floor((target - now) / 1000);

    const years = Math.floor(diffInSeconds / (3600 * 24 * 365.25));
    const remainingSecondsAfterYears = diffInSeconds % (3600 * 24 * 365.25);

    const months = Math.floor(remainingSecondsAfterYears / (3600 * 24 * 30.44));
    const remainingSecondsAfterMonths = remainingSecondsAfterYears % (3600 * 24 * 30.44);

    const days = Math.floor(remainingSecondsAfterMonths / (3600 * 24));
    const hours = Math.floor((remainingSecondsAfterMonths % (3600 * 24)) / 3600);
    const minutes = Math.floor((remainingSecondsAfterMonths % 3600) / 60);
    const seconds = Math.floor(remainingSecondsAfterMonths % 60);

    return {
        years,
        months,
        days,
        hours,
        minutes,
        seconds
    };
}

// These two are just helpers, they curate spring data, values that are later being interpolated into css
export const to = (i) => ({
    x: 0,
    y: i * -4,  // Slight offset to create a staggered effect
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100
});

export const from = (_i) => ({
    x: 0,
    rot: 0,
    scale: 1.5,
    y: window.innerHeight + 100 // Start from just below the bottom of the screen
});


// This is being used down there in the view, it interpolates rotation and scale into a css transform
export const trans = (r, s) => `perspective(1500px) rotateX(0deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`
