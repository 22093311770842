import * as React from 'react';
import {StyledCard} from "../components/card/StyledCard";
import {SaveTheDate} from "./SaveTheDate/SaveTheDate";
import {OurStory} from "./SaveTheDate/OurStory";
import {Photos} from "./SaveTheDate/Photos";
import PHOTOS from "../utils/photos.json";
import {Email} from "./SaveTheDate/Email";

export const SaveTheDateRoute = () => {
    return (
        <StyledCard
            pages={[
                <SaveTheDate/>,
                <Email/>,
                <OurStory/>,
                <Photos cards={PHOTOS}/>,
            ]}
        />
    );
}
