import * as React from 'react';
import {
    CardContent,
    Grid,
    Link,
    Typography,
} from "@mui/material";

export const Email = () => {
    return (
        <CardContent>
            <Grid
                container
                alignContent={'space-between'}
                sx={{
                    height: '100%',
                }}
            >
                <Grid item xs={12}>
                    <Typography variant="h5" fontSize='24px'>Save the Date</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">

                        Dear Friends and Family,
                        <br/>
                        <br/>
                        We are thrilled to announce our upcoming wedding, and we would love for you to be a part of it!
                        <br/>
                        <br/>
                        Date: July 12-13, 2025
                        <br/>
                        Location: Hartford, Connecticut
                        <br/>
                        <br/>
                        Please mark your calendar and save the date!
                        <br/> More details, including the official invitation and
                        RSVP information, will follow soon.
                        <br/>
                        <br/>
                        We can’t wait to celebrate with you and make this occasion truly memorable.
                        <br/>
                        <br/>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Love,</Typography>
                    <Typography variant="h5">Palak and Vijay</Typography>
                </Grid>
            </Grid>

        </CardContent>
    );
}
