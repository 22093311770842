import TexturedBackground from '../images/background-smooth-texture_1154-649.jpg';

export const primary = {
    50: '#F6FEF6',
    100: '#E3FBE3',
    200: '#C7F7C7',
    300: '#A1E8A1',
    400: '#51BC51',
    500: '#1F7A1F',
    600: '#136C13',
    700: '#0A470A',
    800: '#042F04',
    900: '#021D02',
};

export const secondary = {
    50: '#F9F0FF',
    100: '#E9CEFD',
    200: '#D49CFC',
    300: '#B355F6',
    400: '#750AC2',
    500: '#6709AA',
    600: '#490679',
    700: '#3B0363',
    800: '#2F024F',
    900: '#23023B',
};

const getDesignTokens = () => ({
    palette: {
        primary: {
            light: primary[200],
            main: primary[500],
            dark: primary[800],
            contrastText: primary[50],
        },
        secondary: {
            light: secondary[300],
            main: secondary[500],
            dark: secondary[800],
        },
    },
    typography: {
        fontFamily: ['"Cormorant Garamond"', 'serif'].join(','),
        h1: {
            fontSize: 60,
        },
        h2: {
            fontSize: 48,
        },
        h3: {
            fontSize: 42,
        },
        h4: {
            fontSize: 36,
        },
        h5: {
            fontSize: 20,
        },
        h6: {
            fontSize: 18,
        },
        subtitle1: {
            fontSize: 18,
        },
        subtitle2: {
            fontSize: 16,
        },
        body1: {
            fontSize: 15,
        },
        body2: {
            fontSize: 14,
        },
        caption: {
            fontSize: 12,
        },
        cursiveTitle: {
            fontFamily: ['"Cormorant Garamond"', 'serif'].join(','),
            fontSize: 54,
        },
        cursive: {
            fontFamily: ['"Cormorant Garamond"', 'serif'].join(','),
            fontSize: 14,
        },
    },
});

export default function getBaseTheme() {
    return {
        ...getDesignTokens(),
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    'html, body, #root': {
                        padding: '6px 4px 6px 4px',
                        height: '100%',
                        overflow:'hidden'
                    },
                    body: {
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${TexturedBackground})`,
                        '@media (min-width:600px)': {
                            backgroundImage: `url(${TexturedBackground})`,
                        },
                    }
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        textAlign: 'center',
                        height: '100%'
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: 'black', // Darker color to emphasize the inset effect
                    },
                },
            },
        },
    };
}
