import React, {useState} from 'react';
import {useSprings, animated, to as interpolate} from '@react-spring/web';
import {useDrag} from '@use-gesture/react';
import {ThemeProvider, useTheme} from '@mui/material/styles';
import * as utils from '../../utils/utils';
import {FadingDiv} from "../../components/card/FadingDiv";
import {useMediaQuery} from "@mui/material";


export const Photos = ({cards}) => {

    const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
    const [props, api] = useSprings(cards.length, (i) => ({
        ...utils.to(i),
        from: utils.from(i),
    })); // Create a bunch of springs using the helpers above

    // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction, and velocity
    const bind = useDrag(
        ({args: [index], active, movement: [, my], direction: [, yDir], velocity: [, vy]}) => {
            const trigger = vy > 0.2 && yDir === -1; // If you flick hard enough upwards, it should trigger the card to fly out
            if (!active && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
            api.start((i) => {
                if (index !== i) return; // We're only interested in changing spring-data for the current spring
                const isGone = gone.has(index); // Only move the card if the swipe is upwards (yDir === -1)
                const y = isGone ? (200 + window.innerHeight) * yDir : active && yDir === -1 ? my - 100 : 0; // Rotate only if swiping up
                const rot = yDir === -1 ? my / 500 + (isGone ? yDir * 10 * vy : 0) : 0; // Scale the card up if active, regardless of swipe direction
                const scale = active ? 1.1 : 1;

                return {
                    y,
                    rot,
                    scale,
                    delay: undefined,
                    config: {friction: 50, tension: active ? 800 : isGone ? 200 : 500},
                };
            });
            if (!active && gone.size === cards.length)
                setTimeout(() => {
                    gone.clear();
                    api.start((i) => utils.to(i));
                }, 600);
        }
    );

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const photoText = isSmallScreen ? 'Swipe up to see more!' : 'Click and drag up to see more!'
    const calcPhotoDimensions = (orientation) => {
        const isPortrait = orientation === "portrait";
        const height = isSmallScreen ? (isPortrait ? '360px' : '260px') : (isPortrait ? '500px' : '400px');
        const width = isSmallScreen ? (isPortrait ? '260px' : '360px') : (isPortrait ? '400px' : '500px');
        return {height, width};
    };


    // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
    return (
        <>
            <ThemeProvider theme={theme}>
                {props.map(({x, y, rot, scale}, i) => {
                        const {height, width} = calcPhotoDimensions(cards[i].orientation);
                        return (
                            <animated.div
                                key={i}
                                style={{
                                    marginTop: '200px',
                                    marginRight: '10px',
                                    position: 'absolute',
                                    transform: interpolate([x, y], (x, y) => `translate3d(${x}px, ${y}px, 0)`),
                                }}
                            >
                                {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
                                <animated.div
                                    {...bind(i)}
                                    style={{
                                        transform: interpolate([rot, scale], utils.trans),
                                        backgroundImage: `url(img/${cards[i].url})`,
                                        width: width,
                                        height: height,
                                        backgroundColor: '#fff',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        willChange: 'transform',
                                        border: '1em solid #E6E5DE',
                                        borderBottom: '3em solid #E6E5DE',
                                        borderRadius: '3px',
                                        boxShadow: '0 2.5px 10px -2px rgba(50, 50, 73, 0.4), 0 2px 2px -2px rgba(50, 50, 73, 0.3)',
                                    }}
                                >
                                    {i === props.length - 1 ?
                                        <div style={{
                                            position: 'absolute',
                                            bottom: -30,
                                        }}>
                                            <FadingDiv text={photoText}/>
                                        </div> : null}
                                </animated.div>
                            </animated.div>
                        )
                    }
                )}
            </ThemeProvider>
        </>
    );
};
