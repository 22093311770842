import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import getBaseTheme from "./theme/baseTheme";
import {RouteContainer} from "./routes/RouteContainer";
import {BrowserRouter} from "react-router-dom";
import {Grid} from "@mui/material";


export default function App() {
    const defaultTheme = createTheme(getBaseTheme());
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>

            {/*<MobileNav/>*/}
            <Grid
                container
                direction="row"
                justifyContent="center"
                height={'100%'}
            >

                <BrowserRouter>
                    <RouteContainer/>
                </BrowserRouter>
            </Grid>
        </ThemeProvider>
    );
}