import React, { useState, useEffect } from 'react';

export const FadingDiv = ({text}) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        // Set a timer to trigger the fade-out after 5 seconds
        const timer = setTimeout(() => {
            setVisible(false);
        }, 10000);

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <span
            style={{
                opacity: visible ? 1 : 0,
                transition: 'opacity 1s ease-out',
                backgroundColor: 'transparent',
                borderRadius: '8px',
                textAlign: 'center',
            }}
        >
            {text}
        </span>
    );
};
