import * as React from 'react';
import {
    Container,
    useMediaQuery,
    Box, IconButton,
} from "@mui/material";
import PalmLeaves from "../../images/blurred-green-palm-leaves-off-white-background_53876-101416.jpg"
import {useTheme} from "@mui/material/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {TransitionGroup} from 'react-transition-group';
import {useSwipeable} from 'react-swipeable';

export const StyledCard = ({pages}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentPage, setCurrentPage] = React.useState(0);

    const handleNext = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
    };

    const handlePrevious = () => {
        setCurrentPage((prevPage) => (prevPage - 1 + pages.length) % pages.length);
    };

    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true, // Allows swiping with the mouse as well
    });

    return (
        <Container
            {...handlers}
            sx={{
                margin: isSmallScreen ? '30px 0px 30px 0px' : '30px 200px 30px 200px',
                position: 'relative',
                overflow: 'hidden',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: `
                  0px 5px 15px rgba(0, 0, 0, 0.3),   /* First shadow: Close to the container */
                  0px 10px 30px rgba(0, 0, 0, 0.2),  /* Second shadow: A bit further */
                  0px 15px 45px rgba(0, 0, 0, 0.1)   /* Third shadow: Further out */
                `,
                '::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundImage: `url(${PalmLeaves})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'brightness(0.9) contrast(1.2) saturate(1.8) hue-rotate(190deg) sepia(0.26) blur(7px)',
                    zIndex: -1,
                },
            }}
        >
            <TransitionGroup
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        padding: '20px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                >
                    {pages[currentPage]}
                </Box>
            </TransitionGroup>
            {!isSmallScreen && (
                <Box sx={{
                    display: 'flex',
                    marginTop: '40vh',
                    justifyContent: 'space-between',
                }}>
                    <IconButton size="small" onClick={handlePrevious}>
                        <ArrowBackIosNewIcon fontSize="inherit"/>
                    </IconButton>
                    <IconButton size="small" onClick={handleNext}>
                        <ArrowForwardIosIcon fontSize="inherit"/>
                    </IconButton>
                </Box>
            )}

            <style jsx>{`
                .page-enter {
                    opacity: 0;
                    transform: rotateY(90deg);
                }

                .page-enter-active {
                    opacity: 1;
                    transform: rotateY(0);
                    transition: opacity 300ms, transform 300ms;
                }

                .page-exit {
                    opacity: 1;
                    transform: rotateY(0);
                }

                .page-exit-active {
                    opacity: 0;
                    transform: rotateY(-90deg);
                    transition: opacity 300ms, transform 300ms;
                }
            `}</style>
        </Container>
    );
}
