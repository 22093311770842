import {CardContent, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {timeDifference} from "../../utils/utils";


export const OurStory = () => {
    const targetDate = "2021-11-17T00:00:00Z";

    const [timeLeft, setTimeLeft] = useState(timeDifference(targetDate));
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(timeDifference(targetDate));
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [targetDate]);

    return (
        <CardContent>
            <Grid
                container
                alignContent={'space-between'}
                sx={{
                    height: '100%',
                    overflow: 'auto'
                }}
                padding={2}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" component="span">
                        Our Story
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="span">
                        <b>Palak:</b>
                        <br/>
                        "Is your last name Paneer because I can't get enough of you!" was the message I got on Hinge
                        late one night in September 2021. Vijay had sent this while gaming with his friends 3000
                        miles
                        away in California while I was in my final year of dental school in Boston. Vijay had been
                        traveling to Boston one week a month for work at the time, and was confident the line would
                        warrant a response. Given that my name is Palak, the "paneer" joke had been received one too
                        many times but for some reason I was inclined to respond with a "Haha never heard that one
                        before!"
                        And then I ghosted him for 3 days and left him contemplating whether or not the line was too
                        much. One of Vijay's smarter friends thought it'd be best to follow up with a simple "Hey,
                        how's
                        your day?" and lucky for Vijay, I'm a softie and felt guilty for ghosting him. So we chatted
                        for
                        a bit and ended up setting up our first date in Boston.
                        <br/>
                        <br/>
                        <b>Vijay:</b>
                        <br/>
                        I secured the date! It was my first date in over 2 years since the beginning of the pandemic
                        and
                        so naturally I was a bit nervous. I had crowd-sourced with my friends what I would wear and
                        got
                        to the restaurant early (I'm naturally late to everything) which really underlines the
                        nervousness. I had picked a place in Harvard Square called Alden and Harlow because it
                        looked
                        fancy. Lucky for me, Palak was running late (she's never late) and I sat there wondering if
                        I
                        should get up and wait for her outside or keep the table. She came running in about 10 mins
                        after I sat down, apologizing profusely for being late. She sat down, and started talking to
                        me
                        like we had known each other for years. I was truly taken off guard. I normally talk like
                        this
                        with my friends from high school or college, but here was this girl who I had met 2 minutes
                        ago
                        and made a bad palak paneer joke to, updating me on her life that day as if we were best
                        friends. I was hooked. We sat there munching on roasted carrots and brussel sprouts (neither
                        of
                        us were hungry due to nerves) without a moment of silence. It was the best date I had ever
                        been
                        on, so good that in the car ride home, I texted her to setup our next date.
                        <br/>
                        Spoiler alert, I got date 2!
                        <br/>
                        <br/>
                        <b>Palak:</b>
                        <br/>
                        The Uber ride home from our first date I spend analyzing ever single detail. To my shock, 10
                        minutes after I left him, Vijay had already asking if I was free again this week as he was
                        leaving back to California at the end of the week. When I got home I immediately called my
                        best
                        friend and recounted our date to her. At the end of the call I told her "Shit, I can't find
                        anything wrong with him. If I date him I'll end up marrying him". She told me calm down and
                        to
                        go on the second date. I spent the rest of the night wondering what I should do. In the
                        morning
                        I told him because of my school schedule that I was only available after 8pm on Thursday. I
                        assumed he might not be free and perhaps we would never meet again.
                        <br/>
                        <br/>
                        However, again to my shock, Vijay said yes. He literally met up with me in Seaport late on
                        Thursday, pizza and drinks in hand, and we ate and chatted by the water. I found Vijay had a
                        comforting presence and an infectious smile. Since he was leaving tomorrow and I would not
                        be
                        able to see him for a month I figured I would vet him now. In a sly way I started asking him
                        hard hitting questions. I was hoping he would say something crazy and maybe he wouldn't be
                        so
                        perfect. Instead he answered every question thoughtfully and respectfully. At the end of the
                        night he walked me home and I was internally freaking out because if I did not think
                        anything
                        was wrong with him after our first date, I definitely could not find anything wrong with him
                        now.
                        <br/>
                        <br/>
                        <b>Vijay:</b>
                        <br/>
                        I caught on immediately to her "sly" questioning, don't let her fool you! But of course date
                        2
                        went so well, that our 3rd date the following trip is where we really hit it off. I had
                        planned
                        a simple dinner at Eataly followed by a walk home through the Boston Common which was lit up
                        with Christmas lights. I had my camera with me and started taking photos of her with the
                        lights
                        in the background and she insisted on taking a couple of me, and someone walking by offered
                        to
                        take what would be our very first photo together! We laughed the rest of the way home
                        looking at
                        our impromptu photoshoot and I had asked her to be my girlfriend at the end of the trip.
                        It's
                        been {timeLeft.years} years and {timeLeft.months} months since then and it was by far the
                        best
                        decision she made!
                        <br/>
                        <br/>
                        <b>Palak:</b>
                        <br/>
                        It was by far the best decision HE made!
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    )
}
